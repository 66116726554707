
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img, .container {
  max-width: 100%;
  width: 100%;
}
body {
  overflow-x: hidden;
}

#root{
  font-family: Manrope;
}
.btn-new3 svg{
  margin-left: 14px;
}
#navbarNav li a{
  color: #11181C;
font-family: Manrope;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}


.navBrand{
  font-size: 4rem;
  font-weight:"700px"
}
.gasSvg{
  color: #11181C;
}

.image-container {
  position: relative;
  /* Establish a new stacking context for absolute positioning */
  width: 100%;
  /* Ensure the container takes up the full width */
  height: 86vh;
  /* Full viewport height */
  overflow: hidden;
  /* Hide any overflow */
  display: flex;
  align-items: center;
  background: url('C:\Users\mishr\Desktop\marina\tolers\public\images\boat.png') lightgray 50% / cover no-repeat;
}

.svgIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;
}

.svgIcon1 {
}

.svgIcon2 {
  width: 72%;
  opacity: 0.5;
}

.text-content {
  position: relative;
  z-index: 1;
  color: white;
}

.main-title {
  font-size: 7rem;
  /* Adjust size as needed */
  font-weight: bold;
  margin: 0;
  font-family: Marcellus;
  font-style: normal;
  font-weight: 400;
  line-height: 120px;
  padding: 1;
  word-wrap: break-word;
  
}

.sub-title {
  font-size: 1.5rem;
  /* Adjust size as needed */
  margin: 0;
  font-family: Manrope;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding: 1;
  word-wrap: break-word;
}

.bg1 {
  background: linear-gradient(0deg, rgba(69, 120, 182, 0.60) 0%, rgba(69, 120, 182, 0.60) 100%), #1656A4;
}

.marina {
  color: #1656A4;
  text-align: center;
  font-family: Marcellus;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 72px;
  /* 120% */
}

.marina2 {
  color: #1656A4;
  text-align: center;
  font-family: Marcellus;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 72px;

}

.charter {
  margin-top: 3rem;
}

.imgh {
  height: 23rem;
  width: 25rem;
  border-radius: 12px;
  transition:  0.9s ease-in-out;
  animation-duration: 4s; 
}
.imgh:hover{
  cursor: pointer;
  transition:  0.9s ease-in-out;
}

.marina3 {
  color: #1656A4;
  text-align: center;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  margin-top: 12px;
  font-weight: 700;
  line-height: 32px;
  /* 133.333% */
}

.card {
  margin: 0 0.5em;
  box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
  border: none;
}



.image-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
  border: 2px solid whitesmoke;
  gap: 0;
}

.image-grid img {
  max-width: calc(100% / 3);
  display: block;
  height: 23rem;
  width: 23rem;
}



.ammenitesBox {
  background: rgba(0, 57, 121, 0.6);
  backdrop-filter: blur(6px) rgba(143, 174, 211, 0.6);
}

.ammenitesBoxDesc {
  display: inline-flex;
  padding: 34px 48px 24px 49px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: rgba(0, 57, 121, 0.6);
  backdrop-filter: blur(6px);
  margin: 12px;
  
}
.form-check svg{
  color: #11181C;
}

.am-tittle {
  color: #FFF;
  text-align: center;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  /* 137.5% */
}

.bookingCards {
  border-radius: 16px;
  border: 1px solid #326AAF;
  background: rgba(143, 174, 211, 0.20);
  backdrop-filter: blur(3px);
}

.button {
  display: flex;
  align-items: center;
  background-color: rgba(236, 129, 50, 1);
  width: 14rem;
  border: none;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  height: fit-content;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button svg {
  margin-right: 10px;
  height: 70%;
  color: white;
}

.button:hover {
  background-color: rgba(236, 129, 50, 0.8);
}
.imgbg2{
  background-color: rgba(236, 129, 50, 0.8);
}
.imgbg5{
  background-color: #4578B6;
}

.label {
  color: #326AAF;
  font-family: Manrope;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
}

.flexbox {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.f1 {
  margin-right: 4rem;

}

.label-desc {
  color: #326AAF;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
}

.label-box {
  border-radius: 8px;
  border: 1px solid #326AAF;
  backdrop-filter: blur(6px);
}

.book {
  color: #FFF;
  font-size: larger;
  margin-top: 5px;
}

.btn {

  background-color: red;


}


.icon {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 24px;
  height: 24px;
}

.description {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  overflow-y: auto;
}

.btn-new {
  border-radius: 12px;
 
  background-color: #EC8132;
  padding: 12px 24px;
  ;

  width: 26rem;
  padding: 0.75rem 1.5rem;
  
  font-weight: bold;
  font-size: 1rem;
  
  text-align: center;
  border: none;
  color: white;


}

.sticky-navbar {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 1000; /* Adjust as needed */
}

.marginUtility{
  margin-top: 4rem;
 margin-bottom: 4rem;

}


.btn-new3 {
  border-radius: 12px;
 font-size: 24px;
  background-color: #EC8132;
  padding: 12px 24px;
  ;

  width: 26rem;
  padding: 0.75rem 1.5rem;
  
  font-weight: 600;
  
  text-align: center;
  border: none;
  color: white;
 margin-top: 4rem;
 margin-bottom: 4rem;

}

.btn-new2 {
  border-radius: 8px;
  background-color: #EC8132;
  ;

  width: 18rem;

  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  text-align: center;
  border: none;


}

.blueBox {
  background-color: #1656A4;
}

.talk {
  background: linear-gradient(90deg, #FFC851 0%, #DB9600 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Satoshi;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  /* 150% */
  letter-spacing: -0.48px;
  text-transform: capitalize;
}
.heading{
  color: #FFF;
font-family: Satoshi;
font-size: 52px;
font-style: normal;
font-weight: 500;
line-height: 72px; /* 138.462% */
letter-spacing: -1.04px;
text-transform: capitalize;
}

.headingh2{
  color: #FFF;
font-family: Satoshi;
font-size: 34px;
font-style: normal;
font-weight: 500;
line-height: 72px; /* 138.462% */
letter-spacing: -1.04px;
text-transform: capitalize;
}

.footerdesc{
  color: #FFF;
font-family: Manrope;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 120% */
}



input{
  display: flex;
padding: 16px;
align-items: center;
gap: 27px;
align-self: stretch;
border-radius: 6px;
border: 1px solid #FAFAFA;
background-color: #1656A4;
}

select{
  display: flex;
width: 680px;
padding: 16px;
align-items: center;
gap: 77px;
border-radius: 6px;
border: 1px solid #FAFAFA;
background-color: #1656A4;
}
textarea{
  display: flex;
height: 170px;
padding: 16px;
align-items: flex-start;
gap: 77px;
align-self: stretch;
border-radius: 6px;
border: 1px solid #FAFAFA;
background-color: #1656A4;
}

.btn-yellow {}

.btn-yellow img {
  filter: invert(1);
  /* This will make the SVG white */
}



.position-relative {
  position: relative;
}

.svg-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px; /* Adjust as needed */
  color: white;
  /* Optional: Add background to improve readability */
  width: 100%;
}

App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
#carouselImage img{
  border-radius: 12px;

}
.otherLink{
  color: #11181C;
}
.secondHeading{
  margin-top: 4rem;
  margin-bottom: 3rem;
}
#carouselImage{
 
}

.Row{
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
   margin-top: calc(-1 * var(--bs-gutter-y)); 
   
}



#ratesServices img{
  border-radius: 12px;
}
.imgOther{
  border-radius: 12px;
}

.aboutUsBorder{
  border-radius: 8px;
border: 1px solid rgba(0, 0, 0, 0.20);

block-size: fit-content;


}


.aboutUsBorder label{
  color: #11181C;
font-family: Manrope;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 175% */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.text {
  margin-top: 5px;
}

.text h3 {
  margin: 0;
}

.text ul {
  padding-left: 20px;
}

.card .img-wrapper {
  max-width: 100%;
  height: 13em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card img {
  max-height: 100%;
}
.bookingCards {
  text-align: center;
}

.svg-container {
  max-width: 100%;
  height: auto;
  width: 50%; /* Adjust the SVG width for responsiveness */
}

@media (max-width: 576px) {
  .bookingCards {
    padding: 2rem 1rem; /* Reduce padding on small screens */
  }
  .nav-t{
    font-size: 2.5rem;
    word-break: break-all;
    
  }
  .navimg img{
    display: none;
  }
  .Row{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
     margin-top: calc(-1 * var(--bs-gutter-y)); 
    
    
  }
  .marginLeft{
    margin-left: 0;
  }
 

  .marina {
    font-size: 1.5rem; /* Reduce heading size on small screens */
  }
  .utilitesFlex{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .label, .label-desc p {
    font-size: 0.9rem;
    margin: 0; /* Adjust label and description font size */
  }

  .btn-new {
    font-size: 1rem; /* Adjust button font size */
  }

  .svg-container {
    width: 80%; /* Further adjust SVG size on small screens */
  }
}



@media (max-width: 767px) {
  .card .img-wrapper {
    height: 17em;
  }
}


/* Media Query for Small Screens (Mobile) */
@media (max-width: 767px) {
  
  .marina2 {
    font-size: 2rem;
  
  }

  .main-title {
    font-size: 19px;
    line-height: 60px;
    
    overflow-wrap: break-word; /* Breaks long words if necessary */
    word-break: break-word;
  }

  .sub-title {
    font-size: 8px;
    line-height: 24px;
    
    overflow-wrap: break-word; /* Breaks long words if necessary */
    word-break: break-word;
  }
  .img-fluid{
    height: 15rem;
    width: 15rem;
  }
  .svg-overlay{
    margin-left: 0;
  }

  
  .nav-flags{
    height: 4px;
    width: 4px;

  }
  .navBrand{
    font-size: 1rem;
    font-weight:"700px"
  }
  .navItems{
    font-size: 6px;
  }
}

@media (max-width: 768px) {
  .navbar-brand{
    display: none;
  }
  .image-grid2 img {
    max-width: 100%; /* Each image takes full width */
    height: auto; /* Adjust the height as per the image's aspect ratio */
    width: 20rem;
    height:20rem /* Full width for smaller screens */
  }
  
  .ammenitesBoxDesc{
    margin:0
  }
  .marginLeft{
    margin-left: 18px;
  }
  *{
    width: 100%;
  }
  
}

/* Media Query for Extra Small Screens (Very Small Mobile) */
